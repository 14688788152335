body {
  margin: 0;
  padding: 0;
  transition: background-color var(--transition-duration) ease, color var(--transition-duration) ease;
  background-color: var(--site-background);
  color: var(--color);
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  scrollbar-width: none; /* Disable Scrollbar on Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: auto;
}

.AppContainer {
  width: 100%;
  padding-top: 0;  /* Remove default padding */
}

.AppContainer.has-navbar {
  padding-top: 100px;  /* Add padding only when navbar is visible */
}

.ContentContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.ThemeToggleButton {
  position: fixed;
  top: 20px;
  right: 15px;
  background: var(--linkColor);
  color: var(--background);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 50%;
  z-index: 1000;
}

@media (max-width: 768px) {
  .ThemeToggleButton {
    padding: 5px;
    font-size: 0.8em;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 769px) {
  .ThemeToggleButton {
    right: 15px;
  }
}