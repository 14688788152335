.HomePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 60px;
}

.HomePageHeader {
  text-align: center;
  margin-bottom: 40px;
}

.HomePageTitle {
  font-size: 3em;
  color: var(--text);
  margin: 20px 0;
}

.HomePageSubTitle {
  font-size: 1.3em;
  color: var(--text);
}

.HomePageSeparator {
  border: 0;
  height: 1px;
  background-color: var(--color);
  margin: 20px auto;
  width: 80%;
  max-width: 600px;
}