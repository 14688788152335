.AppContainer {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.AboutPageHeader {
  text-align: center;
  margin-bottom: 40px;
}

.AboutPageName {
  font-size: 3em;
  color: var(--text);
  margin: 0;
}

.AboutPageSubTitle {
  font-size: 1.3em;
  color: var(--text);
}

.AboutPageSection {
  margin-bottom: 50px;
}

.AboutPageSectionTitle {
  font-size: 2em;
  color: var(--text);
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.AboutPageSeparator {
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, var(--linkColor), var(--linkColor));
  background-size: 200% 100%;
  animation: scrollGradient 5s linear infinite;
  box-shadow: 0 0 5px white;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.AboutPageSeparator::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: shine 15s infinite;
}

@keyframes scrollGradient {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 200% 100%;
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

.AboutPageText {
  font-size: 1.1em;
  color: var(--theme-color);
  line-height: 1.6;
  transition: color var(--transition-duration) ease;
}

.AboutPageList {
  list-style: none;
  padding: 0;
}

.AboutPageListItem {
  margin-bottom: 15px;
  font-size: 1.1em;
  color: var(--theme-color);
  padding: 5px;
}

.AboutPageListItem:hover {
  transform: scale(1.02);
  background: var(--theme-hoverBackground);
}

.AboutPageContactLink {
  color: var(--linkColor);
  text-decoration: none;
  font-weight: bold;
}

.AboutPageContactLink:hover {
  text-decoration: underline;
}