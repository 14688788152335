.nav-bar-container {
  background-color: var(--navbar-background);
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  max-width: fit-content;
  margin: 0px auto;
  margin-top: 30px;
  border-radius: 15px;
  transition: opacity var(--transition-duration) ease;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  visibility: visible;
  opacity: 1;
}

.nav-bar-container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;  /* Change to absolute when hidden */
  height: 0;          /* Collapse height when hidden */
  padding: 0;         /* Remove padding when hidden */
  margin: 0;          /* Remove margin when hidden */
  overflow: hidden;   /* Hide overflow when collapsed */
}

.nav-bar-container.theme-toggled {
  transition: background-color var(--transition-duration) ease;
}

.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.base-nav-link {
  color: var(--color);
  text-decoration: none;
  font-size: 1.2em;
  transition: color var(--transition-duration) ease;
  font-weight: bold;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.base-nav-link:hover {
  color: var(--linkColor);
}

.base-nav-link.active {
  border-bottom: 2px solid var(--linkColor);
}

.active-dot {
  position: absolute;
  bottom: -8px;
  width: 6px;
  height: 6px;
  background-color: var(--linkColor);
  border-radius: 50%;
  transition: left var(--transition-duration) ease; 
}

.active-dot.initial {
  transition: none;
}